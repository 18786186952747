import { useAutoAnimate } from '@formkit/auto-animate/react'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios, { AxiosError } from 'axios';
import React, { ChangeEvent, FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const api_url = process.env.REACT_APP_API_URL;  

type Props = {}

const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const LoginPage = (props: Props) => {
  const navigate = useNavigate();
  const [showSignIn, setShowSignIn] = useState(true);
  const [dataToSend, setDataToSend] = useState<{
    email: string;
    firstName: string;
    lastName: string;
    password: string;
  }>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [animateCTABtn, disableCTAanimationBtn] = useAutoAnimate<HTMLButtonElement>();


  const toggleSignUp = () => {
    setShowSignIn((prev) => !prev);
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDataToSend((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      }
    })
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (showSignIn) {
      await handleSignIn();
    } else {
      await handleSignUp();
    }
    setIsLoading(false);
  }

  const handleSignUp = async () => {
    try {
      if (dataToSend?.email?.length === 0) {
        toast.error("Please enter your email")
        return;
      }
      if (!dataToSend.email.match(validRegex)) {
        toast.error("Please enter a valid email")
        return;
      }
      if (dataToSend?.firstName?.length === 0) {
        toast.error("Please enter your first name")
        return;
      }
      if (dataToSend?.lastName?.length === 0) {
        toast.error("Please enter your last name")
        return;
      }
      if (dataToSend?.password?.length === 0) {
        toast.error("Please enter your password")
        return;
      }
      var response = await axios.post<{
        email: string,
        is_verified: boolean,
        first_name: string,
        last_name: string,
      }>(`${api_url}/user/`, {
        "email": dataToSend.email,
        "password": dataToSend.password,
        "first_name": dataToSend.firstName,
        "last_name": dataToSend.lastName,
      });
      if (response.data) {
        toast.success("Please check your email, to verify your account.")
        setDataToSend({
          email: "",
          firstName: "",
          lastName: "",
          password: "",
        })
        setShowSignIn(true);
        // localStorage.setItem("token", response.data?.token)
        // router.push("/");
      }
    } catch (e) {
      if ((e as any).response !== null) {
        if (((e as AxiosError).response?.data as any)?.error !== null) {
          toast.error(((e as AxiosError).response?.data as any)?.error)
        } else if (((e as AxiosError).response?.data as any)?.non_field_errors !== null) {
          toast.error(((e as AxiosError).response?.data as any)?.non_field_errors.map((item: string) => item + "\n").join(""))
        } else {
          toast.error((e as AxiosError).message)
        }
      }
      console.error(e);
    }

  }

  const handleSignIn = async () => {
    try {
      if (dataToSend?.email?.length === 0) {
        toast.error("Please enter your email")
        return;
      }
      if (!dataToSend.email.match(validRegex)) {
        toast.error("Please enter a valid email")
        return;
      }
      if (dataToSend?.password?.length === 0) {
        toast.error("Please enter your password")
        return;
      }
      var response = await axios.post<{
        expiry: string,
        token: string
      }>(`${api_url}/user/login`, {
        "username": dataToSend.email,
        "password": dataToSend.password,
      });
      if (response.data) {
        toast.success("Logged in successfully.")
        localStorage.setItem("token", response.data?.token)
        // setCookie('token', response.data?.token)
        navigate("/");
        setDataToSend({
          email: "",
          firstName: "",
          lastName: "",
          password: "",
        })
      }
    } catch (e) {
      if ((e as any).response !== null) {
        if (((e as AxiosError).response?.data as any)?.error !== null) {
          toast.error(((e as AxiosError).response?.data as any)?.error)
        } else if (((e as AxiosError).response?.data as any)?.non_field_errors !== null) {
          toast.error(((e as AxiosError).response?.data as any)?.non_field_errors.map((item: string) => item + "\n").join(""))
        } else {
          toast.error((e as AxiosError).message)
        }
      }
      console.error(e);
    }
  }

  return (
    <div className="flex items-center min-h-screen bg-[#1e1e1e] text-white">
      <div className="flex justify-center flex-1 h-full max-w-4xl mx-auto overflow-hidden">
        <div className="flex flex-col md:flex-row justify-center bg-white/5 rounded-lg shadow-xl w-10/12 max-w-[400px]">
          <div className="flex items-center justify-center p-6 w-full">
            <div className="w-full">
              <div className="flex justify-center">
              <img src={"/mocaw_logo.png"} alt="Mocaw" width={170} height={150} className='mb-6 h-[24px]' />
              </div>
              <h1 className="mb-4 text-2xl font-bold text-center text-white">
                { }
                {showSignIn && <span>Login to Your Account</span>}
                {!showSignIn && <span>Create an account</span>}
              </h1>
              <form onSubmit={handleSubmit}>
                <div>
                  <label className="block text-sm">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    autoComplete='email'
                    onChange={handleChange}
                    value={dataToSend.email}
                    className="w-full px-4 py-2 text-sm border border-[#1e1e1e]/20 rounded-md focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-white/25"
                    placeholder="" />
                </div>
                {!showSignIn && <>
                  <div className='mt-4'>
                    <label className="block text-sm">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      autoComplete='first-name'
                      onChange={handleChange}
                      value={dataToSend.firstName}
                      className="w-full px-4 py-2 text-sm border border-[#1e1e1e]/20 rounded-md focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-white/25"
                      placeholder="" />
                  </div>
                  <div className='mt-4'>
                    <label className="block text-sm">
                      Last Name
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      autoComplete='last-name'
                      value={dataToSend.lastName}
                      onChange={handleChange}
                      className="w-full px-4 py-2 text-sm border border-[#1e1e1e]/20 rounded-md focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-600 bg-white/25"
                      placeholder="" />
                  </div>
                </>}
                <div>
                  <label className="block mt-4 text-sm">
                    Password
                  </label>
                  <input
                    name="password"
                    autoComplete='password'
                    onChange={handleChange}
                    value={dataToSend.password}
                    className="w-full px-4 py-2 text-sm border rounded-md border-[#1e1e1e]/20 bg-white/25 focus:border-blue-400 focus:outline-none focus:ring-1 focus:ring-blue-600"
                    placeholder=""
                    type="password" />
                </div>
                <input type="submit" hidden />
              </form>
              <p className="mt-4">
                {showSignIn && <span className="mb-4 text-sm text-center text-white/90">Don't have an account? </span>}
                {!showSignIn && <span className="mb-4 text-sm text-center text-white/90">Have an account? </span>}
                <button onClick={(e) => {
                  e.preventDefault();
                  toggleSignUp();
                }}
                  className="text-sm text-orange-400 hover:underline">
                  {showSignIn && <span>Sign up here.</span>}
                  {!showSignIn && <span>Sign in here.</span>}
                </button>
              </p>
              <button ref={animateCTABtn} disabled={isLoading} onClick={handleSubmit} className="w-full flex justify-center items-center gap-x-2 px-4 py-2 mt-4 text-sm font-medium leading-5 text-center text-white transition-colors bg-orange-400 disabled:bg-orange-600 duration-300 border border-transparent rounded-lg active:bg-orange-400 hover:bg-orange-500 focus:outline-none focus:shadow-outline-blue">
                {isLoading && <FontAwesomeIcon spin icon={faCircleNotch} />}
                {showSignIn && <span>Log in</span>}
                {!showSignIn && <span>Sign Up</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginPage