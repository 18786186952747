import { ChangeEvent, useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import config from '@/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const api_url = process.env.REACT_APP_API_URL;


const EmailVerificationPage = () => {
  const navigate = useNavigate();
  
  // get token from url variable :token
  const token = window.location.href.split("/").pop();
  console.log(token);
  const verifyEmail = () => {
    axios.post(`${api_url}/user/verify-email`, {
      "token": token,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((res) => {
      if (res.status === 200) {
        toast.success("Email verified successfully!");
        // localStorage.removeItem("token");
        navigate("/login")
      } else {
        toast.error("Something went wrong, please try again later! or contact support at support@mocaw.ai");
        navigate("/login");
      }
    }).catch((e) => {
      if ((e as any).response !== null) {
        if (((e as AxiosError).response?.data as any)?.error !== null) {
          toast.error(((e as AxiosError).response?.data as any)?.error)
        } else if (((e as AxiosError).response?.data as any)?.non_field_errors !== null) {
          toast.error(((e as AxiosError).response?.data as any)?.non_field_errors.map((item: string) => item + "\n").join(""))
        } else {
          toast.error((e as AxiosError).message)
        }
        navigate("/login")
      }
      console.error(e)
    })
  }

  useEffect(() => {
    verifyEmail();
  }, [])

  return (
    <main className="flex flex-col h-screen overflow-hidden text-white">
      <nav className='h-16 flex pt-6 items-center justify-between bg-black w-full px-6'>
        <img src={"/mocaw_logo.png"} alt="Mocaw" width={300} height={150} className='' />
      </nav>
      <div className='flex flex-1 bg-black overflow-auto'>
        <div className='flex-1 bg-black gap-y-6 flex flex-col p-6'>
          
          
        </div>
        <div className='flex-1 flex flex-col'>
          <div className='mt-6 flex-1 flex flex-col me-6 overflow-hidden bg-secondary rounded-md max-lg:hidden'>
            
          </div>
          
        </div>
      </div>
    </main>
  )
}

export default EmailVerificationPage;
