import { Editor } from '@monaco-editor/react'
import { createSpeechlySpeechRecognition } from '@speechly/speech-recognition-polyfill';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import MicrophoneIcon from '@/components/icons/microphone';
import SendIcon from '@/components/icons/send';
import config from '@/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
const api_url = process.env.REACT_APP_API_URL;
const code_chk_interval = parseInt(process.env.REACT_APP_CODE_CHK_INTERVAL!);

const appId = 'f2c90588-f98c-4025-801e-2cab2dc10eb1';
const SpeechlySpeechRecognition = createSpeechlySpeechRecognition(appId);
SpeechRecognition.applyPolyfill(SpeechlySpeechRecognition);

const InterviewPage = () => {
  const {
    finalTranscript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const navigate = useNavigate();

  const [transcripts, setTranscripts] = useState<{
    message: string,
    from: string,
  }[]>([]);
  const [mounted, setIsMounted] = useState(false);
  const [aiLoading, setAiLoading] = useState<boolean>(false);
  const [inputMessage, setInputMessage] = useState("");
  const [editorCode, setEditorCode] = useState("");
  const [timer, setTimer] = useState<number | null>(null);
  const [language, setLanguage] = useState<{
    id: number;
    name: string
  }>(
    { id: 19, name: 'javascript' }
  );

  const messagesRef = useRef<HTMLDivElement>(null);

  const options = {
    minimap: {
      enabled: false,
    },
    acceptSuggestionOnCommitCharacter: true,
    acceptSuggestionOnEnter: 'on',
    accessibilitySupport: 'auto',
    autoIndent: false,
    automaticLayout: true,
    codeLens: true,
    colorDecorators: true,
    contextmenu: true,
    cursorBlinking: 'blink',
    cursorSmoothCaretAnimation: false,
    cursorStyle: 'line',
    disableLayerHinting: false,
    disableMonospaceOptimizations: false,
    dragAndDrop: false,
    fixedOverflowWidgets: false,
    folding: true,
    foldingStrategy: 'auto',
    fontLigatures: false,
    formatOnPaste: false,
    formatOnType: false,
    hideCursorInOverviewRuler: false,
    highlightActiveIndentGuide: true,
    links: true,
    mouseWheelZoom: false,
    multiCursorMergeOverlapping: true,
    multiCursorModifier: 'alt',
    overviewRulerBorder: true,
    overviewRulerLanes: 2,
    quickSuggestions: true,
    quickSuggestionsDelay: 100,
    readOnly: false,
    renderControlCharacters: false,
    renderFinalNewline: true,
    renderIndentGuides: true,
    renderLineHighlight: 'all',
    renderWhitespace: 'none',
    revealHorizontalRightPadding: 30,
    roundedSelection: true,
    rulers: [],
    scrollBeyondLastColumn: 5,
    scrollBeyondLastLine: true,
    selectOnLineNumbers: true,
    selectionClipboard: true,
    selectionHighlight: true,
    showFoldingControls: 'mouseover',
    smoothScrolling: false,
    suggestOnTriggerCharacters: true,
    wordBasedSuggestions: true,
    wordSeparators: `~!@#$%^&*()-=+[{]}\|;:'",.<>/?`,
    wordWrap: 'off',
    wordWrapBreakAfterCharacters: '\t})]?|&,;',
    wordWrapBreakBeforeCharacters: '{([+',
    wordWrapBreakObtrusiveCharacters: '.',
    wordWrapColumn: 80,
    wordWrapMinified: true,
    wrappingIndent: 'none',
  };

  const startListening = () => {
    if (browserSupportsSpeechRecognition && typeof window === "object") {
      SpeechRecognition.startListening({ continuous: true })
    }
  };

  const handleEditorWillMount = (monaco: any) => {
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      target: monaco.languages.typescript.ScriptTarget.Latest,
      module: monaco.languages.typescript.ModuleKind.ES2015,
      allowNonTsExtensions: true,
      lib: ['es2018'],
    });

    monaco.editor.defineTheme('onedark', {
      base: 'vs-dark',
      inherit: true,
      rules: [
        {
          token: 'comment',
          foreground: '#5d7988',
          fontStyle: 'italic'
        },
        { token: 'constant', foreground: '#e06c75' }
      ],
      colors: {
        'editor.background': '#3F3F3F',
      }
    });
  }

  const startInterview = async () => {
    setAiLoading(true);
    axios.get<{
      ai_message: string,
      audio_url: string
      session_duration: number
      status: string
      status_message: string
      detail: string
    }>(`${api_url}/interview/start`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem("token")}`,
      }
    }).then((res) => {
      if (res.data.status === "success") {
        setTranscripts((prev) => [
          ...prev, { from: "Mocaw", message: res.data.ai_message },
        ])
        setTimer(res.data.session_duration);
      } else {
        toast.error(res.data.detail);
        //  wait one second before redirecting
        setTimeout(() => {
          navigate("/");
        }, 1000)
        return;
      }
    }).catch((e) => {
      toast.error(e.response.data.detail)
      setTimeout(() => {
        navigate("/");
      }, 1000)
    }).finally(() => {
      setAiLoading(false);

    })
  }

  const exitInterview = () => {
    axios.post(`${api_url}/interview/end`, {
    }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem("token")}`,
      }
    }).then((res) => {
      // localStorage.removeItem("token");
      navigate("/")
    }).catch((e) => {
      if ((e as any).response !== null) {
        if (((e as AxiosError).response?.data as any)?.error !== null) {
          toast.error(((e as AxiosError).response?.data as any)?.error)
        } else if (((e as AxiosError).response?.data as any)?.non_field_errors !== null) {
          toast.error(((e as AxiosError).response?.data as any)?.non_field_errors.map((item: string) => item + "\n").join(""))
        } else {
          toast.error((e as AxiosError).message)
        }
        navigate("/")
      }
      console.error(e)
    })
  }


  const SubmitCode = () => {
    //  check editorCode is not ""
    if (editorCode === "") {
      toast.warning("No code to submit!")
      return;
    }
    if (aiLoading) {
      // show taost message saying waiting for response
      toast.warning("waitng for response!")
      return;
    }
    setAiLoading(true);

    axios.post(`${api_url}/interview/submit-code`,
      {
        "code": editorCode,
      }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem("token")}`,
      }
    }).then((res) => {
      setAiLoading(false);
      setTranscripts((prev) => [
        ...prev, { from: "Mocaw", message: res.data.ai_message },
      ])
      if (messagesRef.current !== null) {
        setTimeout(() => {
          if (messagesRef.current !== null) {
            messagesRef.current!.scrollIntoView({ behavior: "smooth" });
          }
        }, 125)
      }
    }).catch((err) => {
      console.error('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
      setAiLoading(false);
      console.error(err);
      console.error('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
      toast.error("Error occured while submitting code!")
    })
  }


  const askAIQuestion = async (message: string) => {
    setAiLoading(true);
    axios.post(`${api_url}/interview/talk`,
      {
        "message": message,
      }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem("token")}`,
      }
    }).then((res) => {
      setAiLoading(false);
      setTranscripts((prev) => [
        ...prev, { from: "Mocaw", message: res.data.ai_message },
      ])
      if (messagesRef.current !== null) {
        setTimeout(() => {
          if (messagesRef.current !== null) {
            messagesRef.current!.scrollIntoView({ behavior: "smooth" });
          }
        }, 125)
      }
    }).catch((err) => {
      console.error('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
      setAiLoading(false);
      console.error(err);
      console.error('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
    })
  }

  const sendMessage = () => {
    if (aiLoading) return;
    setTranscripts((prev) => [...prev, { from: "You", message: inputMessage }]);
    if (messagesRef.current !== null) {
      setTimeout(() => {
        messagesRef.current!.scrollIntoView({ behavior: "smooth" });
      }, 125)
    }
    askAIQuestion(inputMessage);
    setInputMessage("");
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputMessage(e.target.value);
  }

  const handleLangaugeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const language = config.supportedLanguages.find((item) => item.id === parseInt(e.target.selectedOptions[0].id));
    if (language) {
      setLanguage({
        id: parseInt(e.target.selectedOptions[0].id),
        name: language.name,
      })
    }
  }

  const handleEditorChange = (code: string | undefined) => {
    if (code === undefined) return
    setEditorCode(code);
  }

  const sendCodeChangeRequest = () => {
    axios.post<{
      ai_message: string | null,
      audio_url: string | null,
      session_duration: number | null,
      status: string | null,
      status_message: string | null
    }>(`${api_url}/interview/code-change`,
      {
        "code": editorCode,
      }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem("token")}`,
      }
    }).then((res) => {
      if (res.data.status === "success" && res.data.ai_message !== null) {
        setTranscripts((prev) => [
          ...prev, { from: "Mocaw", message: res.data.ai_message! },
        ])
      }
    }).catch((e) => {
      if ((e as any).response !== null) {
        if (((e as AxiosError).response?.data as any)?.error !== null) {
          toast.error(((e as AxiosError).response?.data as any)?.error)
        } else if (((e as AxiosError).response?.data as any)?.non_field_errors !== null) {
          toast.error(((e as AxiosError).response?.data as any)?.non_field_errors.map((item: string) => item + "\n").join(""))
        } else {
          toast.error((e as AxiosError).message)
        }
      }
      console.log('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
      console.log(e);
      console.log('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
    })
  }

  useEffect(() => {
    const delayDebounceFn = setInterval(() => {
      if (editorCode) {
        sendCodeChangeRequest();
      }
    }, code_chk_interval)

    return () => clearInterval(delayDebounceFn)
  }, [editorCode])

  useEffect(() => {
    if (mounted) return;
    const token = localStorage.getItem("token");
    if (token === null) {
      navigate("/login");
      return;
    }
    setIsMounted(true);
    startInterview();
  }, [])

  useEffect(() => {
    if (!mounted) return;
    if (listening === false && finalTranscript.length !== 0) {
      setTranscripts((prev) => [...prev, { from: "You", message: finalTranscript }]);
      if (messagesRef.current !== null) {
        setTimeout(() => {
          messagesRef.current!.scrollIntoView({ behavior: "smooth" });
        }, 125)
      }
      resetTranscript();
      askAIQuestion(finalTranscript);
    }
  }, [finalTranscript])

  useEffect(() => {
    if (timer !== null) {
      const timerFn = setTimeout(() => {
        setTimer((prev) => prev! - 1)
      }, 1000)
      if (timer <= 0) {
        clearTimeout(timerFn);
        toast.info("Interview has ended, please start another interview!")
        navigate("/")
      }
    }
  }, [timer])

  return (
    <main className="flex flex-col h-screen overflow-hidden text-white">
      <nav className='h-16 flex pt-6 items-center justify-between bg-black w-full px-6'>
        <img src={"/mocaw_logo.png"} alt="Mocaw" width={300} height={150} className='' />
        {timer !== null && <span className='text-3xl lg:text-5xl 2xl:text-5xl  text-white/50 uppercase font-semibold'>{(Math.floor(timer / 60) % 60).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })}:{(timer % 60).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        })}</span>}
      </nav>
      <div className='flex flex-1 bg-black overflow-auto'>
        <div className='flex-1 bg-black gap-y-6 flex flex-col p-6'>
          <div className='flex-1 bg-secondary rounded-md overflow-auto'>
            {transcripts.map((transcript, idx) => {
              return <div key={`transcript-${idx}`} className={`border-b flex border-white/10 p-4 ${transcript.from !== "You" && "justify-end"}`}>
                <span className={`block max-w-[90%] whitespace-pre-line p-4 rounded-lg ${transcript.from === "You" ? "bg-black/30" : "bg-black/50"}`}>
                  <span className={`font-thin`}>{transcript.from}</span>: "{transcript.message}"
                </span>
              </div>
            })}
            {aiLoading && <div className='border-b border-white/10 p-4 flex justify-end'><span className='font-thin'>Loading ...</span></div>}
            <div style={{ float: "left", clear: "both" }}
              ref={messagesRef}>
            </div>
          </div>
          <div className='flex gap-x-4 h-12 w-full'>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (aiLoading) return;
                sendMessage();
              }}
              className='w-full'>
              <div className='flex items-center gap-x-4 pe-4 h-full bg-secondary rounded-md '>
                <input type='text' onChange={handleInputChange} value={inputMessage} className='bg-transparent w-full h-full px-4 outline-none text-white' placeholder='Type here...' />
                <button disabled={aiLoading} type='submit'>
                  <SendIcon className='fill-white/80' />
                </button>
              </div>
            </form>
            <div>
              <button
                disabled={aiLoading}
                onTouchStart={startListening}
                onMouseDown={startListening}
                onTouchEnd={SpeechRecognition.stopListening}
                onMouseUp={SpeechRecognition.stopListening}
                className={`${listening ? 'bg-red-600' : 'bg-orange-400'} p-3 rounded-full transition-all duration-300`}
              >
                <MicrophoneIcon className={`${listening ? "fill-black" : "fill-white"} transition-all duration-300`} />
              </button>
            </div>
          </div>
        </div>
        <div className='flex-1 flex flex-col'>
          <div className='mt-6 flex-1 flex flex-col me-6 overflow-hidden bg-secondary rounded-md max-lg:hidden'>
            <div className='flex items-center gap-x-4 px-6 py-3 border-b border-white/20'>
              <span className='text-white/90'>Languages: </span>
              <select defaultValue={language.id} onChange={handleLangaugeChange} className='bg-transparent outline-none border border-white/20 px-4 py-1 rounded-sm'>
                {config.supportedLanguages.map((item) => {
                  return <option key={item.id} id={item.id.toString()} value={item.id}>{item.label ?? item.name}</option>
                })}
              </select>
            </div>
            <div className='h-full'>
              <Editor
                height="100%"
                path={language.name}
                value={editorCode}
                onChange={(e) => handleEditorChange(e)}
                language={language.name}
                // options={options}
                beforeMount={handleEditorWillMount}
                theme='onedark'
              />
            </div>
          </div>
          <div className='flex justify-end items-center h-24 py-6 me-6'>
            <div className='flex-grow'>
              <button onClick={SubmitCode} className='border text-orange-500  hover:bg-orange-500 hover:text-white duration-300 flex gap-x-2 items-center border-orange-500 px-4 py-2 rounded-md'>
                <FontAwesomeIcon icon={faDoorOpen} className='h-5' />
                <span className='font-semibold '>Submit Code</span>
              </button>
            </div>
            <div>
              <button onClick={exitInterview} className='border text-orange-500  hover:bg-orange-500 hover:text-white duration-300 flex gap-x-2 items-center border-orange-500 px-4 py-2 rounded-md'>
                <FontAwesomeIcon icon={faDoorOpen} className='h-5' />
                <span className='font-semibold '>Leave Interview</span>
              </button>
            </div>
          </div>

        </div>
      </div>
    </main>
  )
}

export default InterviewPage;
