import { faDoorOpen, faGear, faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const api_url = process.env.REACT_APP_API_URL;



type Props = {}

const planBuyButtons: {
  plan: string;
  price: number;
  buyButtonId: string;
  publishableKey: string;
  features?: string[],
}[] = [
    {
      plan: "starter",
      price: 40,
      buyButtonId: "buy_btn_1NxBw5EP2g1czxwGMUasVG58",
      publishableKey: "pk_test_51NqFpJEP2g1czxwGCFm9DiO9HR5nhCpBXr5uujjzoUh3GqCr3mMuJc31XD4CpLb29zp30UsvnfJsbBr8LLxYqoVr003gzeuSYA",
      features: [
        "Full refund if you cancel before using the 2nd credit",
        "3 Mock interview session",
        "Comprehensive personalized performance report post each interview"
      ]
    },
    {
      plan: "popular",
      price: 60,
      buyButtonId: "buy_btn_1NxCEdEP2g1czxwGzG3mwCil",
      publishableKey: "pk_test_51NqFpJEP2g1czxwGCFm9DiO9HR5nhCpBXr5uujjzoUh3GqCr3mMuJc31XD4CpLb29zp30UsvnfJsbBr8LLxYqoVr003gzeuSYA",
      features: [
        "Full refund if you cancel before using the 2nd credit",
        "5 Mock interview session",
        "Comprehensive personalized performance report post each interview"
      ]
    },
    {
      plan: "saver",
      price: 100,
      buyButtonId: "buy_btn_1NxCHbEP2g1czxwG4l7PqAPm",
      publishableKey: "pk_test_51NqFpJEP2g1czxwGCFm9DiO9HR5nhCpBXr5uujjzoUh3GqCr3mMuJc31XD4CpLb29zp30UsvnfJsbBr8LLxYqoVr003gzeuSYA",
      features: [
        "Full refund if you cancel before using the 2nd credit",
        "10 Mock interview session",
        "Comprehensive personalized performance report post each interview"
      ]
    },
  ];

const Homepage = (props: Props) => {

  const [isSideMenuExpanded, setSideMenuExpanded] = useState(true);
  const [currentIdx, setCurrentIdx] = useState<number>(0);
  const [isLoggingOut, setIsLogginOut] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<number>(1);
  const navigate = useNavigate();

  const logout = async () => {
    setIsLogginOut(true);
    axios.post(`${api_url}/user/logout`, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem("token")}`,
      }
    }).then((res) => {
      localStorage.removeItem("token");
      navigate("/login");
    }).catch((err) => {
      localStorage.removeItem("token");
      navigate("/login");
      console.error('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
      console.error(err);
      console.error('=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-');
    }).finally(() => {
      setIsLogginOut(false);
    })
  }

  // State to store the credit value
  const [credit, setCredit] = useState<string | "N/A">("N/A");
  const [is_demo, setIsDemo] = useState<boolean | false>(false);
  const get_credit = async () => {
    setIsLogginOut(true);
    axios.get<{
      status: string,
      detail: string,
      credit: number,
      is_demo: boolean
    }>(`${api_url}/user/credit`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Token ${localStorage.getItem("token")}`,
      }
    }).then((res) => {
      console.log(res.data);
      if (res.data.status === "success") {
        // update variable credit
        setCredit(res.data.credit.toString());
        setIsDemo(res.data.is_demo);
      } else {
        toast.error(res.data.detail);
        setCredit("N/A");
        setIsDemo(res.data.is_demo);
        return;
      }
    }).catch((e) => {
      toast.error(e.response.data.detail);
      setCredit("N/A");
    })
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token === null) {
      navigate("/login");
    } else {
      // Call get_credit when the component is mounted
      get_credit();
    }
  }, [])

  return (
    <div className='flex h-screen w-full bg-black text-white'>
      <div className={`${!isSideMenuExpanded ? "w-fit px-6" : "flex-[2] px-6 items-start"} justify-start py-10 flex flex-col gap-y-2 h-full`}>
        <img src={"/mocaw_logo.png"} alt="Mocaw" width={170} height={150} className='mb-6 h-[24px]' />
        <div className='w-full h-full flex flex-col justify-between'>
          <div className='flex flex-col gap-y-4'>
            <button onClick={() => setCurrentIdx(0)} className={`flex gap-x-2 items-center ${currentIdx === 0 ? "bg-secondary" : ""} hover:bg-secondary duration-300 w-full py-2 px-4 rounded-md`}>
              <FontAwesomeIcon icon={faHome} className='w-[18px] text-white' />
              {isSideMenuExpanded && <span className='text-white/80'>Home</span>}
            </button>
            {/* <button onClick={() => setCurrentIdx(1)} className={`flex gap-x-2 items-center ${currentIdx === 1 ? "bg-secondary" : ""} hover:bg-secondary duration-300 w-full py-2 px-4 rounded-md`}>
              <FontAwesomeIcon icon={faGear} className='w-[18px] text-white' />
              {isSideMenuExpanded && <span className='text-white/80'>Account</span>}
            </button> */}
          </div>
          <button onClick={() => logout()} className={`flex gap-x-2 items-center duration-300 w-full py-2 px-4 rounded-md border border-red-500 hover:bg-red-500 group`}>
            <FontAwesomeIcon icon={faDoorOpen} className='w-[18px] text-red-500 group-hover:text-white' />
            {isSideMenuExpanded && <span className='text-red-500 group-hover:text-white'>Logout</span>}
          </button>
        </div>
      </div>
      <div className='flex-[8] overflow-auto bg-secondary w-full h-full px-10 py-10'>
        {currentIdx === 0 && <div className='flex flex-col justify-center items-center h-full'>
          <div className='min-w-[200px] flex flex-col items-center py-6 rounded-lg bg-[#222222] max-w-[340px]'>
            <span className='uppercase font-semibold text-xl mb-10'>START INTERVIEW</span>
            <div className='w-full flex flex-col max-w-[80%] mb-4'>
              <ul className='list-disc'>
                <li className='mb-4'>You have {credit} credit left.</li>
                <li className='mb-4'>Once you click begin you will be taken to the interview screen.</li>
                <li className='mb-4'>The interview is 45 mins long, the timer is locaetd at top right corner.</li>
                <li className='mb-4'>If the AI intervieer mentions something unclear or something you disagree with you can ask him about it, don't be afraid to push back or be inquisitive.</li>
                <li className='mb-4'>You can ask your interviewer any clarification questions about the interview any time.</li>
                <li className='mb-4'>Sometimes your AI interviewer won't respond (we're working on this!) nudge him by sending a "?" mark.</li>
                <li className='mb-4'>Use the "sumbit code" button to submit your code for feedback by your AI interviewer during the coding stage of the interview.</li>
                <li className='mb-4'>Once you are done with the interview end it by clicking the "leave interview" button.</li>
              </ul>
            </div>
            <button
              onClick={() => navigate("/interview")}
              disabled={(credit === "N/A" || credit === "0") && !is_demo} // Disable the button when credit is "N/A" or "0"
              className={`bg-red-500 border-2 border-white bg-[#e00d49] rounded-md px-10 py-2 w-fit text-white hover:bg-white hover:text-black hover:border-red-500 duration-300 font-bold uppercase ${
                (credit === "N/A" || credit === "0") && !is_demo ? "cursor-not-allowed opacity-50" : "" // Add disabled styling
              }`}
            >
              {(credit === "N/A" || credit === "0") && !is_demo ? "No credit left" : "Begin"}
            </button>
          </div>

        </div>}
        {currentIdx === 1 && <div className='flex flex-col gap-y-20 pt-10'>
          <div className='flex flex-col gap-y-6'>
            <div className='w-full border-b'>
              <span className='text-3xl'>Profile</span>
            </div>
            <div className='flex flex-col gap-y-4'>
              <div>
                <label className="block text-sm">
                  First Name:
                </label>
                <span>Mohamed</span>
              </div>
              <div>
                <label className="block text-sm">
                  Last Name:
                </label>
                <span>Gibreel</span>
              </div>
              <div>
                <label className="block text-sm">
                  Email:
                </label>
                <span>m.adilgibreel@gmail.com</span>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-y-6'>
            {/* <div className='w-full flex justify-between border-b'>
              <span className='text-3xl'>Credit</span>
              <span className='text-xl text-white/70'>Used 1 out of 3</span>
            </div> */}
            {/* <div className='flex flex-col gap-y-4'>
              <div>
                <div className='grid grid-cols-3 gap-x-2 mb-6 mt-2'>
                  {planBuyButtons.map((item) =>
                    <div className='min-w-[200px] flex flex-col items-center py-6 rounded-lg bg-[#222222]'>
                      <span className='uppercase font-semibold text-md mb-4'>{item.plan}</span>
                      <span className='uppercase font-semibold text-5xl mb-4'>${item.price}</span>
                      {(item.features ?? []).length > 0 && <div className='w-full flex flex-col max-w-[80%] mb-4'>
                        <ul className='list-disc'>
                          {item.features?.map((feature, idx) => <li className={`mb-2 ${idx === 0 && "text-[#e00d49]"}`}>{feature}</li>)}
                        </ul>
                      </div>}
                      <stripe-buy-button
                        buy-button-id={item.buyButtonId}
                        publishable-key={item.publishableKey}
                        customer-email="mohamed@gibreel.dev"
                      >
                      </stripe-buy-button>
                    </div>
                  )}

                </div>
              </div>
            </div> */}
          </div>
        </div>}
        {currentIdx === 2 && <div className='flex flex-col px-10 pt-10 justify-center items-center gap-y-4 h-full'>
          <span className='text-3xl text-black font-bold'>Credit Left: 3</span>
          <button className='bg-black border border-white rounded-md px-10 py-2 w-fit text-white'>Start Interview</button>
        </div>}
      </div>
    </div>
  )
}

export default Homepage