import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import './index.css';
import Homepage from './pages/home';
import LoginPage from './pages/auth';
import EmailVerificationPage from './pages/email_verification';
import InterviewPage from './pages/interview';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PaymentSuccessScreen from './pages/payment/success';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Homepage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/interview",
    element: <InterviewPage />,
  },
  {
    path: "/verify-email/:token",
    element: <EmailVerificationPage />,
  },
  {
    path: "/payment",
    children: [
      {
        path: "/payment/success",
        element: <PaymentSuccessScreen />
      }
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <>
    <RouterProvider router={router} />
    <ToastContainer />
  </>
);
